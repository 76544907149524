

































import Vue from 'vue'
import Component from 'vue-class-component'

import { SubscriptionStatus } from '@/api'
import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import ListItem from '@/components/Layout/ListItem.vue'

@Component({
  components: {
    Content,
    Toolbar,
    ListItem
  },
  computed: {
    currentUser () {
      return this.$store.state.currentUser
    }
  }
})
export default class SubscriptionActions extends Vue {
  public back () {
    this.$router.push({ name: 'subscription' })
  }

  SubscriptionStatus = SubscriptionStatus;
}
